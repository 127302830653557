input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}


:root {
  --whiteBg: #fff;
  --greyBg: #f1f1f1;
  --greenBg: #1e90ff;
  --redFont: #1e90ff;
  --greyFont: #b7b7b7;
  --blackFont: #252525;
  --blackShadow: #1e90ff;

  --font-size--normal: 16px;
  --font-size--huge: 42px;

  --theme: #e81d2d;
  --green: #7ad03a;
  --red: #a00;
  --orange: #ffba00;
  --blue: #2ea2cc;
  --primary: #a46497;
  --primary-text: white;
  --secondary: #ebe9eb;
  --secondary-text: #515151;
  --highlight: #77a464;
  --highligh-text: white;
  --content-bg: #fff;
  --subtext: #767676;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Open Sans Hebrew",Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #ffffff; */
  background-color: #ffffff;
  overflow: hidden;
}

svg {
  outline: none;
}
img {
  /* user-drag: none; */
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* ::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #3e434f;
  border-radius: 10px;
} */

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap");
